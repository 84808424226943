.dropdownList {
    position: relative;
    margin-bottom: 5px;

    // &.dropdownList-dense {
    //     margin-bottom: 5px;
    // }
    &>input,
    &>.inputWrapper {
        width: 100%;
        font-size: 0.7rem;
        word-wrap: break-word;
        line-height: 0.9em;
        outline: 0;
        white-space: normal;
        min-height: 0.9em;
        background: #fff;
        display: inline-block;
        padding: 0.4em 3em 0.4em 0.4em;
        color: rgba(0, 0, 0, 0.87);
        box-shadow: none;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.13rem;
        transition: box-shadow 0.1s ease, width 0.1s ease;
        text-overflow: ellipsis;
    }

    &>input::placeholder {
        // color: #000;
        color: #9ca3c1;
    }

    // &>input:disabled {
    //     background-color: #eef0f3;
    //     // &::placeholder {
    //     //     color: #9ca3c1;
    //     // }
    // }

    &.compact {
        margin-bottom: 0;

        &>input {
            padding: 0.2em 0.4em 0.2em 0.4em;
        }
    }

    &.isDisabled .form-icon {
        background-color: #eef0f3;
        cursor: not-allowed;
        opacity: 0.5;
    }

    .form-icon {
        color: #50596c;
    }

    .inputWrapper {
        input {
            border: none;
            outline: 0;
        }
    }

    &.has-values input::placeholder {
        color: var(--color-primary);
        // font-weight: bold;
    }
}

.has-error {
    .inputWrapper {
        background: #fffaf7;
        border-color: #e85600 !important;
    }
}

.dropdownList-menu {
    position: absolute;
    width: 100%;
    z-index: 10;
    background: #fff;
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    border-top-width: 0;
    outline: 0;
    border-radius: 0 0 0.3rem 0.3rem;
    transition: opacity 0.1s ease;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15); // border-color: rgba(34, 36, 38, .15);
    // @media (max-width: 768px) {
    //     width: auto;
    // }
}

.dropdownList-menu-item {
    position: relative;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 0.9rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.8rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    padding: 0.4rem 0.5rem;
    white-space: normal;
    word-wrap: normal;

    &.isActive {
        color: rgba(0, 0, 0, 0.95);
        background: rgba(0, 0, 0, 0.03);
    }

    &.isSelected {
        color: rgba(0, 0, 0, 0.95);
        font-weight: 700;
    }

    &.disabled{
        color: rgba(0, 0, 0, 0.35);
        pointer-events: none;
    }
}

.dropdownMultiple {
    input.input-lg {
        height: 1.3rem;
    }
}